<template>
  <form class="card" @submit.prevent="guardar">
    <h3 class="card-title mb-1 mt-2">Cambiar contraseña</h3>
    <div class="cols2 mt-0 mb-1">
      <pd-input
        v-model="oldpassword"
        id="password"
        type="password"
        label="Contraseña actual"
        placeholder="Ingrese su contraseña actual"
        :required="$v.oldpassword.$dirty && !$v.oldpassword.required"
      ></pd-input>
    </div>
    <div class="cols2 mt-0 mb-1">
      <pd-input
        v-model="password"
        id="password"
        type="password"
        label="Nueva contraseña"
        placeholder="Ingrese su nueva contraseña"
        :required="$v.password.$dirty && !$v.password.required"
      ></pd-input>
      <pd-input
        v-model="confirmPassword"
        id="confirmpassword"
        type="password"
        label="Confirmar contraseña"
        placeholder="Confirme su nueva contraseña"
        :required="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
      >
        <small
          slot="validation"
          class="form-message text-danger"
          v-if="
            $v.password.$dirty &&
            $v.confirmPassword.$dirty &&
            password != confirmPassword
          "
          >Las contraseñas deben coincidir.</small
        >
      </pd-input>
    </div>
    <div>
      <p class="check-validation" :class="classMinLength">
        <icon feather="circle"></icon> La nueva contraseña debe tener 6
        caracteres mínimo.
      </p>
      <p class="check-validation" :class="classUppercase">
        <icon feather="circle"></icon> Debe contener al menos una mayúscula
      </p>
      <p class="check-validation" :class="classHasNumber">
        <icon feather="circle"></icon> Debe contener al menos un número
      </p>
      <p class="check-validation" :class="classSameAs">
        <icon feather="circle"></icon> Las contraseñas deben coincidir
      </p>
    </div>
    <div class="d-flex right">
      <button type="submit" class="btn btn-primary btn-align-right">
        Guardar mis datos
      </button>
    </div>
  </form>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import Swal from "sweetalert2";
export default {
  name: "CambiarPassword",
  data() {
    return {
      oldpassword: null,
      password: null,
      confirmPassword: null,
    };
  },
  validations: {
    oldpassword: { required },
    password: { required },
    confirmPassword: { required },
  },
  computed: {
    sameAs: function () {
      if (this.password && this.confirmPassword) {
        if (this.password === this.confirmPassword) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    classSameAs: function () {
      if (this.sameAs == null) {
        return "text-secondary";
      }
      if (this.sameAs) {
        return "text-success";
      }
      return "text-danger";
    },
    minlength: function () {
      if (this.password != null) {
        if (this.password.length >= 6) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    classMinLength: function () {
      if (this.minlength == null) {
        return "text-secondary";
      }
      if (this.minlength) {
        return "text-success";
      }
      return "text-danger";
    },
    hasUppercase: function () {
      if (this.password != null) {
        return /[A-Z]/.test(this.password);
      } else {
        return null;
      }
    },
    classUppercase: function () {
      if (this.hasUppercase == null) {
        return "text-secondary";
      }
      if (this.hasUppercase) {
        return "text-success";
      }
      return "text-danger";
    },
    hasNumber: function () {
      if (this.password != null) {
        return /\d/.test(this.password);
      } else {
        return null;
      }
    },
    classHasNumber: function () {
      if (this.hasNumber == null) {
        return "text-secondary";
      }
      if (this.hasNumber) {
        return "text-success";
      }
      return "text-danger";
    },
  },
  methods: {
    guardar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if(!this.minlength){
        Swal.fire(
            "Error",
            `<p>La nueva contraseña debe contener un mínimo de 6 caracteres.</p>`,
            "error"
          );
        return false
      }
      if(!this.hasUppercase){
        Swal.fire(
            "Error",
            `<p>La nueva contraseña debe contener al menos una mayúscula.</p>`,
            "error"
          );
        return false
      }
      if(!this.hasNumber){
        Swal.fire(
            "Error",
            `<p>La nueva contraseña debe contener al menos un número.</p>`,
            "error"
          );
        return false
      }
      if(!this.sameAs){
        Swal.fire(
            "Error",
            `<p>Las contraseñas deben coincidir.</p>`,
            "error"
          );
        return false
      }
      PersonasService.api
        .cambiarPassword({
          oldpassword: this.oldpassword,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", `<p>Se ha guardado con éxito</p>`, "success");
            this.$store
              .dispatch("attempt", localStorage.getItem("token"))
              .then(() => {
                this.$router.push({ name: "Inicio" });
              });
          } else {
            Swal.fire("Error", `<p>No se pudo guardar</p><p>${response.mensaje}</p>`, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(
            "Error",
            `<p>No se pudo guardar</p><p>${error}</p>`,
            "error"
          );
        });
    },
  },
};
</script>
<style scoped>
.check-validation {
  display: flex;
  align-items: flex-start;
  font-size: 0.85rem;
}
.check-validation .feather {
  height: 1rem;
  font-weight: bold;
  stroke-width: 3;
}
</style>